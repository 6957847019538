import React from "react"
import { BreakpointProvider } from "./src/utils/breakpoint"

const queries = {
  xs: `(min-width: 0)`,
  sm: `(min-width: 576px)`,
  md: `(min-width: 768px)`,
  lg: `(min-width: 992px)`,
  xl: `(min-width: 1200px)`,
  xxl: `(min-width: 1400px)`,
}

export const wrapRootElement = ({ element }) => (
  <BreakpointProvider queries={queries}>{element}</BreakpointProvider>
)

const addScript = (url) => {
  const script = document.createElement(`script`)
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript(`https://fast.wistia.com/embed/medias/c9mr3eiz89.jsonp`)
    addScript(`https://fast.wistia.com/assets/external/E-v1.js`)
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  loadDynamicsForm(location.pathname)
}

/**
 * Load scripts for contact-us dynamics crm form.
 *
 * Scripts have to be re-added every time we load the page.
 *
 * @param {string} route
 */
const loadDynamicsForm = (route) => {
  if (route && route.includes(`/contact`)) {
    if (window[`MsCrmMkt`]) {
      window[`MsCrmMkt`] = null
    }
    addScript(
      `https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.70.1097.0`,
    )
    addScript(
      `https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.70.1097.0`,
    )
  }
}
